'use client'

import { useLocale } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { useMemo, type FormEvent } from 'react'

import { LANGUAGE } from 'lib/i18n/config'
import { usePathname } from 'lib/next-intl'

export const useLanguageSelect = () => {
  const locale = useLocale()
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const handleLocalesChange = (event: FormEvent<HTMLSelectElement>) => {
    const locale = event.currentTarget.value
    window.location.href =
      `/${locale}${pathname}` +
      (searchParams ? `?${searchParams.toString()}` : '')
  }

  const localeList = useMemo(() => {
    return LANGUAGE.filter(child => child.key !== locale).map(child => ({
      name: child.key,
      title: child.name
    }))
  }, [locale])

  const currentLocale = useMemo(() => {
    return LANGUAGE.find(child => child.key === locale)?.name
  }, [locale])

  return {
    localeList,
    locale,
    localeName: currentLocale,
    handleLocalesChange
  }
}
