import { cx, type CxOptions } from 'class-variance-authority'
import type * as React from 'react'
import * as ReactTwc from 'react-twc'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: CxOptions) {
  return twMerge(cx(inputs))
}

export const twc = ReactTwc.createTwc({
  compose: cn
})

export type TwCompose = typeof cn

export type TwcComponentProps<TComponent extends React.ElementType> =
  ReactTwc.TwcComponentProps<TComponent, TwCompose>

export type TwcComponentWithAsChild<TComponent extends React.ElementType> =
  Omit<ReactTwc.TwcComponentProps<TComponent, TwCompose>, 'asChild'> & {
    $asChild?: boolean
  }

export { cn as default }
