'use client'

import IconArrowDown from 'asset/icon/arrow_down.svg'
import { twc } from 'util/cn'
import { useLanguageSelect } from './use-language-select'

const LanguageSelectorContainer = twc.div`relative`
LanguageSelectorContainer.displayName = 'LanguageSelectorContainer'
const Option = twc.option`text-current`
Option.displayName = 'Option'
const Select = twc.select`text-current opacity-0 absolute left-0 top-0 w-full h-full border-none`
Select.displayName = 'Select'
const Display = twc.div`text-current flex items-center justify-between space-x-[4px]`
Display.displayName = 'Display'

interface ILanguageSelector {
  className?: string | undefined
}
const LanguageSelector = (props: ILanguageSelector) => {
  const { locale, localeName, handleLocalesChange, localeList } =
    useLanguageSelect()
  return (
    <LanguageSelectorContainer className={props.className}>
      <Display>
        <span>{localeName}</span>
        <IconArrowDown className='fill-current sq-[16px]' />
      </Display>
      <Select value={locale} onChange={handleLocalesChange}>
        <Option value={locale} data-testid='select-option'>
          {localeName}
        </Option>
        {localeList?.map(locale => (
          <Option
            key={locale.name}
            value={locale.name}
            data-testid='select-option'
          >
            {locale.title}
          </Option>
        ))}
      </Select>
    </LanguageSelectorContainer>
  )
}

export default LanguageSelector
