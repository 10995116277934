'use client'

import Link from 'component/block/link'
import { ARCHIVE_OF_PREVIOUS_TERMS } from 'constant/terms'
import { usePathname } from 'lib/next-intl'

const PreviousTerms = () => {
  const pathname = usePathname()

  if (pathname.includes('archive')) {
    return null
  }

  return (
    <div className='mx-auto w-full min-w-[200px] max-w-[980px] whitespace-break-spaces px-[15px] pb-[45px] min768:px-[45px] min768:pb-[96px]'>
      <Link
        href={`${pathname}/archive`}
        className='text-2xl font-semibold text-primary-p1'
      >
        {ARCHIVE_OF_PREVIOUS_TERMS}
      </Link>
    </div>
  )
}

export default PreviousTerms
