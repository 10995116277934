import { createSharedPathnamesNavigation } from 'next-intl/navigation'
import { permanentRedirect as nextPermanentRedirect } from 'next/navigation'

import { DEFAULT_LANGUAGE, LANGUAGE } from './i18n/config'

export const { Link, useRouter, usePathname, redirect } =
  createSharedPathnamesNavigation({ locales: LANGUAGE.map(l => l.value) })

type PermanentRedirect = typeof nextPermanentRedirect
type RestOfParameters<T> = T extends (first: any, ...rest: infer P) => any
  ? P
  : never

export const permanentRedirect = (
  locale: string,
  url: string,
  ...args: RestOfParameters<PermanentRedirect>
) => {
  const prefix = locale === DEFAULT_LANGUAGE ? '' : `/${locale}`
  return nextPermanentRedirect(`${prefix}${url}`, ...args)
}
