// --- Terms related ---
export const TERMS_NAME = {
  PLAYSEE_RULES: 'playsee_rules',
  PRIVACY_NOTICE: 'privacy_notice',
  USER_TERMS_OF_SERVICE: 'user_terms_of_service',
  ADDITIONAL_TERMS_OF_SERVICE_BUSINESS: 'additional_terms_of_service(business)',
  ADVERTISING_TERMS_OF_SERVICE: 'advertising_terms_of_service',
  ADVERTISING_POLICIES: 'advertising_policies',
  INTELLECTUAL_PROPERTY: 'intellectual_property',
  COUPON_TERMS: 'coupon_terms',
  OPEN_SOURCE: 'open_source_libraries'
}

export const TERMS_PATH = {
  [TERMS_NAME.PLAYSEE_RULES]: 'rules',
  [TERMS_NAME.PRIVACY_NOTICE]: 'privacy',
  [TERMS_NAME.USER_TERMS_OF_SERVICE]: 'user-terms',
  [TERMS_NAME.ADDITIONAL_TERMS_OF_SERVICE_BUSINESS]: 'biz-terms',
  [TERMS_NAME.ADVERTISING_TERMS_OF_SERVICE]: 'ad-terms',
  [TERMS_NAME.ADVERTISING_POLICIES]: 'ad-policies',
  [TERMS_NAME.INTELLECTUAL_PROPERTY]: 'intellectual-property',
  [TERMS_NAME.COUPON_TERMS]: 'coupon-terms',
  [TERMS_NAME.OPEN_SOURCE]: 'open-source'
}

export const TERMS_PATHS = Object.values(TERMS_PATH).reduce(
  (acc, path) => {
    acc[`/${path}`] = true
    acc[path] = true
    return acc
  },
  {} as Record<string, boolean>
)

export const TERMS_PREVIOUS_TITLE = {
  [TERMS_NAME.PLAYSEE_RULES]: 'Previous Playsee Rules',
  [TERMS_NAME.PRIVACY_NOTICE]: 'Previous Privacy Notice',
  [TERMS_NAME.USER_TERMS_OF_SERVICE]: 'Previous User Terms of Service',
  [TERMS_NAME.ADDITIONAL_TERMS_OF_SERVICE_BUSINESS]:
    'Previous Additional Terms of Service (Business)',
  [TERMS_NAME.ADVERTISING_TERMS_OF_SERVICE]:
    'Previous Advertising Terms of Service',
  [TERMS_NAME.ADVERTISING_POLICIES]: 'Previous Advertising Policies',
  [TERMS_NAME.INTELLECTUAL_PROPERTY]: 'Previous Intellectual Property',
  [TERMS_NAME.COUPON_TERMS]: 'Previous Coupon Terms',
  [TERMS_NAME.OPEN_SOURCE]: 'Previous Open Source Libraries'
}

export const ARCHIVE_OF_PREVIOUS_TERMS: string = 'Archive of Previous Terms'
